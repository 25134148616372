<template lang="pug">
  .row
    .actions.col
      AppIconButton.add-new(
        :disabled="!hasEditPermission()"
        icon="plus-circle"
        title="company_system.add_new"
        @click="$emit('add-new')"
      )
      AppIconButton.download(
        disabled
        v-if="withDownload"
        icon="download"
        title="company_system.download"
        @click="$emit('download')"
      )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"

  export default {
    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppPagination: () => import("@/components/elements/AppPagination")
    },

    mixins: [withPermissions],

    props: {
      withDownload: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .actions
    padding-top: 20px
    margin-left: 10px
    max-width: 100%
    display: flex
    align-items: center
    justify-content: space-between

    ::v-deep
      .app-button
        padding-left: 0

    .add-new
      background-color: $default-purple

      ::v-deep
        svg
          path
            fill: $default-white
        .title
          color: $default-white

    .download
      margin-left: 10px
</style>
